<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Direcciones de cliente</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <div class="p-6">

                <vs-table :data="addresses">

                    <template slot="thead">
                        <vs-th>Dirección</vs-th>
                        <vs-th>Número</vs-th>
                        <vs-th>Referencia</vs-th>
                        <vs-th>Zona de entrega</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                            <vs-td :data="tr.address">
                                {{tr.address}}
                                <template slot="edit">
                                    <vs-input v-model="tr.address" class="inputx" placeholder="Dirección"/>
                                </template>
                            </vs-td>

                            <vs-td :data="tr.number">
                                {{tr.number}}
                                <template slot="edit">
                                    <vs-input v-model="tr.number" class="inputx" placeholder="Número de dpto. o of."/>
                                </template>
                            </vs-td>

                            <vs-td :data="tr.reference">
                                {{tr.reference}}
                                <template slot="edit">
                                    <vs-input v-model="tr.reference" class="inputx" placeholder="Referencia"/>
                                </template>
                            </vs-td>

                            <vs-td :data="tr.delivery.name">
                                {{tr.delivery.name}}
                            </vs-td>

                        </vs-tr>
                    </template>
                </vs-table>

            </div>
        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
            <div v-if="progress" style="margin-top: 1rem; width: 100%">
                <vs-progress indeterminate color="primary"></vs-progress>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()
  export default {
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      }
    },
    components: {
      VuePerfectScrollbar,
    },
    data() {
      return {
        client: {},
        addresses: [],
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        progress: false
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        let data = JSON.parse(JSON.stringify(this.data))
        this.client = {
          ...data
        }
        this.loadAddresses()
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any() && this.addresses.length > 0
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      submitData() {
        this.progress = true
        let batch = db.batch()
        this.addresses.forEach((a) => {
          // Update address
          let orderRef = db.collection('addresses').doc(a.id)
          let obj = {
            ...a
          }
          delete obj.id
          batch.update(orderRef, {...obj})
        })

        batch.commit().then(() => {
          this.progress = false
        }).catch(() => {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      },
      loadAddresses() {
        this.addresses = []
        db.collection("addresses").where('clientId', '==', this.client.id)
         .orderBy('createdAt', 'desc').get()
         .then((querySnapshot) => {
           querySnapshot.forEach((doc) => {
             let address = {
               id: doc.id,
               ...doc.data()
             }
             this.addresses.push(address)
           })
         }).catch(() => {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 950px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>

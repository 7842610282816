<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar cliente</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Name -->
        <vs-input data-vv-as="nombre" @blur="client.name= trimString(client.name)" label="Nombre"
                  v-model="client.name"
                  class="w-full" name="name"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Last name -->
        <vs-input data-vv-as="apellido" @blur="client.lastName= trimString(client.lastName)" label="Apellido"
                  v-model="client.lastName"
                  class="mt-5 w-full" name="lastName"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('lastName')">{{ errors.first('lastName') }}</span>


        <!-- Type Document -->
        <vs-select data-vv-as="tipo de documento" v-model="client.typeDocument" label="Tipo de documento"
                   class="mt-5 w-full" v-validate="'required'" name="type">
          <vs-select-item :key="item.id" :value="item.name" :text="item.name"
                          v-for="item in typeDocuments"/>
        </vs-select>
        <span class="text-danger text-sm"
              v-show="errors.has('type')">{{ errors.first('type') }}</span>

        <!-- Document -->
        <vs-input data-vv-as="documento" @blur="client.document= trimString(client.document)" label="Documento"
                  v-model="client.document"
                  class="mt-5 w-full" name="document"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('document')">{{ errors.first('document') }}</span>

        <!-- Business Name -->
        <vs-input data-vv-as="razón social" @blur="client.businessName= trimString(client.businessName)"
                  label="Razón Social"
                  v-model="client.businessName"
                  class="mt-5 w-full" name="businessName"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('businessName')">{{ errors.first('businessName') }}</span>

        <!-- Email -->
        <vs-input @blur="client.email= trimString(client.email)" label="Email" v-model="client.email"
                  class="mt-5 w-full" name="email"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('email')">{{ errors.first('email') }}</span>

        <!-- Telefono -->
        <vs-input data-vv-as="teléfono" @blur="client.phone= trimString(client.phone)" label="Telefono"
                  v-model="client.phone"
                  class="mt-5 w-full" name="phone"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('phone')">{{ errors.first('phone') }}</span>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Actualizar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'
import trimString from '../../mixins/trimString'

const firebase = require('firebase')
// Required for side-effects
require('firebase/firestore')
let db = firebase.firestore()
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data () {
    return {
      client: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      typeDocuments: [
        { name: 'DNI', id: 1 },
        { name: 'RUC', id: 2 },
        { name: 'Pasaporte', id: 3 }
      ],
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      let data = JSON.parse(JSON.stringify(this.data))
      this.client = {
        ...data
      }
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true

          // Update client
          let clientRef = db.collection('clients').doc(this.client.id)

          //Clone to remove the id
          let cloneClient = Object.assign({}, this.client)
          delete cloneClient.id

          // Update client
          clientRef.update({
            ...cloneClient,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            let obj = {
              ...this.client
            }
            this.$store.dispatch('clients/updateClient', { client: obj })
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Cliente',
              text: 'Cliente modificado correctamente.'
            })
            this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
